<template>
    <div class="clientcabin clientcabin-blog">
        <v-container>
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="py-10">
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/reasons/google-news/1.png"
                        width="100%"
                        max-height="400px"
                        contain
                    />
                    <p class="text-md-h3 text-h4 mt-8 text-center">
                        How to Submit a Press Release to Google News
                    </p>
                    <v-card
                        class="blueish black--text px-6 py-5 rounded-lg my-6 elevation-0"
                    >
                        <p class="font-weight-bold">Article at-a-glance</p>
                        <ul>
                            <li>
                                Having press releases as part of your marketing
                                strategy is a great way of increasing visibility
                                and making sure your brand is associated with
                                credible sources.
                            </li>
                            <li>
                                You can submit your press releases to
                                Google-approved news sites such as Hindustan
                                Times and Deccan Herald to be published on
                                Google News.
                            </li>
                            <li>
                                {{ company }} makes it easy to get approved and
                                distributed on Google-approved news sites.
                            </li>
                            <li>
                                With {{ company }}, you’ll get help by marketing
                                professionals with years of experience to ensure
                                your press release content is optimized while
                                adhering to publication guidelines.
                            </li>
                        </ul>
                    </v-card>

                    <p class="text-h4 mt-8 text-center">
                        Why Choose Google News
                    </p>
                    <p>
                        Did you know that Google News press releases can benefit
                        even a local business? Really, there’s no need to be
                        modest and stick to small media outlets. Being seen on
                        Google News and other well-known sites will just make
                        you stand out from the competition that’s aiming too
                        low!
                    </p>
                    <p>
                        When your business gets published on Google News, you
                        have the possibility of being seen by millions of people
                        across the globe. This increased visibility can lead to
                        more traffic, potential leads, and, ultimately, more
                        customers.
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/reasons/google-news/2.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        Data from Think With Google reveals that users spend 31
                        minutes per day on news apps, such as Google News.
                    </p>
                    <p>
                        When users see your content on such a reputable
                        platform, they are more likely to trust your brand –
                        this is important, especially if you’re just getting
                        started and trying to establish your name in the market.
                    </p>
                    <p>
                        In this article, we’ll take a look at how visibility on
                        Google News can benefit your brand and how to get
                        published.
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        Understanding the Google News Audience
                    </p>
                    <p>
                        In all marketing, knowing your audience is the key to a
                        successful campaign. It will help you tailor your
                        content to their interests and habits – increasing the
                        likelihood that your story will be read and shared.
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/reasons/google-news/3.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        Before you sit down to write your press release, you
                        should get familiar with Google News’ audience. This
                        will increase your chances of capturing their attention
                        and being shared.
                    </p>
                    <p>
                        Here is the key Google News audience information as
                        collated from online sources like
                        <a
                            href="https://www.similarweb.com/website/news.google.com/"
                            target="_blank"
                        >
                            SimilarWeb:
                        </a>
                    </p>
                    <ul>
                        <li class="mb-4">
                            <b>User Demographics:</b>
                            The audience is predominantly male (67.26%), with
                            the largest age group being 35-44 years old.
                        </li>
                        <li class="mb-4">
                            <b>Global Reach:</b>
                            Google News is a global news aggregator, pulling
                            from over 20,000 publishers in multiple languages.
                        </li>
                        <li class="mb-4">
                            <b>Geographic Distribution:</b>
                            The primary audience for Google News is based in the
                            United States, which accounts for about 47.64% of
                            traffic. Other significant countries include France
                            (8.66%), Germany (5.63%), and Japan (5.49%).
                        </li>
                        <li class="mb-4">
                            <b>Monthly Visits:</b>
                            Google News attracts approximately 342.3 million
                            visits each month, with a steady increase of 3.98%
                            compared to the previous month.
                        </li>
                        <li class="mb-4">
                            <b>User Trust:</b>
                            Over 60% of people trust Google News more than other
                            outlets - which means you’re increasing your brand’s
                            authority when your press release is published
                            there.
                        </li>
                        <li class="mb-4">
                            <b>Engagement Metrics:</b>
                            Users spend an average of 5 minutes and 50 seconds
                            per visit – it’s a pretty strong level of engagement
                            with the content.
                        </li>
                        <li class="mb-4">
                            <b>User Interests:</b>
                            The audience is generally interested in categories
                            like News & Media Publishing, technology, business,
                            and other areas that highlight current events and
                            trends.
                        </li>
                    </ul>
                    <p class="text-h4 mt-8 text-center">
                        Submit to Google-Approved News Sites
                    </p>
                    <p>
                        While you can’t submit your press release directly to
                        Google News, you can submit your content to
                        Google-approved news sites. These sites meet Google’s
                        quality and content guidelines and journalistic
                        standards – increasing the chances of your press release
                        being picked up.
                    </p>
                    <p class="text-h5">Identifying Approved Sites</p>
                    <p>
                        To do this, you must first find the right
                        Google-approved news sites. Here are some examples of
                        such sites:
                    </p>
                    <ul>
                        <li class="mb-2">Hindustan Times Deccan Herald</li>
                        <li class="mb-2">The Print</li>
                        <li class="mb-2">Outlook India</li>
                        <li class="mb-2">NewsX</li>
                        <li class="mb-2">Asian Age</li>
                        <li class="mb-2">DNA</li>
                        <li class="mb-2">IBTimes</li>
                        <li class="mb-2">Mid Day</li>
                        <li class="mb-2">Vents Magazine</li>
                        <li class="mb-2">Estrade Herald</li>
                    </ul>
                    <p>
                        These sites have established credibility and
                        consistently publish original news content, which is
                        crucial for maintaining their status as Google-approved
                        sources.
                    </p>
                    <p>
                        Evaluate the sites based on their audience, content
                        style, and submission requirements and choose the ones
                        that align with your brand’s message and target
                        demographic so that your press release reaches the right
                        audience.
                    </p>
                    <p class="text-h5">Submission Guidelines</p>
                    <p>
                        Each Google-approved site will have specific guidelines
                        you need to follow – like formatting requirements,
                        content length, and acceptable topics. To get your press
                        release approved and published, you need to follow these
                        rules closely.
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/reasons/google-news/4.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        Your press release needs to follow general as well as
                        site-specific guidelines to be published.
                    </p>
                    <p>
                        Your content should be well-written, informative, and
                        free of errors. It needs to avoid overly promotional
                        language and focus on delivering newsworthy information.
                        Also, make sure your press release includes all the
                        necessary details, such as
                        <b>who, what, when, where, and why.</b>
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        What You Need to Know About the Changes to Google
                        Publisher Center
                    </p>
                    <p>
                        Previously, publishers could create source pages
                        manually in the Publisher Center. In April 2024,
                        <a
                            href="https://support.google.com/news/publisher-center/answer/14859160?sjid=12326342631449782708-AP"
                            target="_blank"
                        >
                            Google released an update
                        </a>
                        that publishers cannot manually add their publications
                        to the Google Publisher Center anymore. Instead,
                        eligible publications will be automatically created by
                        Google in early 2025.
                    </p>
                    <p>
                        So, as long as your site and content adhere to Google’s
                        content policies, it’s automatically considered for
                        inclusion in Google News and other news surfaces.
                    </p>
                    <p class="text-center font-italic text-h5 mx-16 mt-6">
                        “Our content and behavior policies help ensure a
                        positive experience for our users and publishers. To be
                        eligible to appear in Google News or news surfaces,
                        content should follow best practices for article pages
                        and must not violate Google Search’s overall policies,
                        Google Search’s spam policies, or [the] policies for
                        Search features.”
                    </p>
                    <p class="text-body-2 mt-2 px-4 text-center">
                        Google News Policies
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        Key Criteria for Google News Eligibility
                    </p>
                    <p>
                        Here’s a summary of the key criteria your content must
                        follow to be included in Google News:
                    </p>
                    <ol>
                        <li class="mb-4">
                            <b>Content Quality</b>
                            <ul>
                                <li>
                                    Content must be original, accurate, and
                                    provide unique insights. Aggregated content
                                    should be clearly differentiated from
                                    self-generated content.
                                </li>
                                <li>
                                    Articles should be relevant and published
                                    regularly to reflect current events or
                                    trending topics.
                                </li>
                                <li>
                                    Content must be well-written, free of
                                    grammatical errors, and engaging for
                                    readers.
                                </li>
                            </ul>
                        </li>
                        <li class="mb-4">
                            <b>Technical Requirements</b>
                            <ul>
                                <li>
                                    Use permanent and unique URLs for each
                                    article. Your site must be structured in a
                                    way that is easy for Googlebot to crawl.
                                    Content must be provided in HTML format
                                    since non-HTML formats
                                </li>
                                <li>
                                    Content must be provided in HTML format
                                    since non-HTML formats like PDFs cannot be
                                    crawled by Google News. Learn more about how
                                    Google crawling works
                                    <a
                                        href="https://developers.google.com/search/docs/fundamentals/how-search-works"
                                        target="_blank"
                                    >
                                        here.
                                    </a>
                                </li>
                                <li>
                                    The site must be accessible to search engine
                                    bots, with a properly configured robots.txt
                                    file.
                                </li>
                            </ul>
                        </li>
                        <li class="mb-4">
                            <b>Adherence to Google News Policies</b>
                            <ul>
                                <li>
                                    Avoid prohibited content types which are:
                                    <ol>
                                        <li>Dangerous content</li>
                                        <li>Deceptive practices</li>
                                        <li>Harassing content</li>
                                        <li>Hateful content</li>
                                        <li>Manipulated media</li>
                                        <li>Medical content</li>
                                        <li>Sexually explicit content</li>
                                        <li>Violent extremist content</li>
                                        <li>Violent & gory content</li>
                                        <li>Vulgar language & profanity</li>
                                    </ol>
                                </li>
                                <li>
                                    Provide clear dates, bylines, author
                                    information, and contact details to
                                    establish trustworthiness.
                                </li>
                            </ul>
                        </li>
                        <li class="mb-4">
                            <b>Regular Updates</b>
                            <ul>
                                <li>
                                    Sites should publish news articles regularly
                                    (daily or multiple times a week) to
                                    demonstrate active engagement with news
                                    topics.
                                </li>
                            </ul>
                        </li>
                        <li class="mb-4">
                            <b>Niche Focus</b>
                            <ul>
                                <li>
                                    The site should primarily focus on news
                                    articles rather than a mix of content types
                                    (e.g., blogs or product reviews). A
                                    dedicated news section is advisable.
                                </li>
                            </ul>
                        </li>
                        <li class="mb-4">
                            <b>Use of News Sitemap</b>
                            <ul>
                                <li>
                                    Submit a Google News-specific XML sitemap
                                    that includes metadata about your articles,
                                    such as publication dates and titles.
                                </li>
                            </ul>
                        </li>
                        <li class="mb-4">
                            <b>User Experience</b>
                            <ul>
                                <li>
                                    The website design is clean and easy to use
                                    for better overall user experience.
                                </li>
                            </ul>
                        </li>
                    </ol>
                    <p>
                        By meeting these criteria, you can improve your chances
                        of having your press releases being included in Google
                        News.
                    </p>
                    <p>
                        Does this all sound a bit overwhelming? Then,
                        {{ company }} is here for you, offering a simple,
                        done-for-you solution!
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        How {{ company }} Helps with Google News Submission
                    </p>
                    <p>
                        {{ company }} is a content creation and distribution
                        platform that can help submit your press releases to
                        Google News. We identify your niche and build a strong
                        brand by creating high-quality, well-researched content
                        in various formats – such as news articles, blog posts,
                        slideshows, podcasts, infographics, and videos. We then
                        distribute this content across high-authority sites like
                        Google News, Yahoo Finance, Business Insider, YouTube
                        and Spotify.
                    </p>
                    <p>
                        <b>
                            Our 3-step process to drive long-term organic
                            traffic:
                        </b>
                    </p>
                    <ul>
                        <li class="mb-4">
                            <b>Create:</b>
                            We discover what people want to buy from you and
                            create the ideal content to capture their attention.
                        </li>
                        <li class="mb-4">
                            <b>Repurpose:</b>
                            We create a news article, blog post, video,
                            infographic, slideshow, and an audio ad from a
                            single piece of seed content.
                        </li>
                        <li class="mb-4">
                            <b>Distribute:</b>
                            We distribute all this content across hundreds of
                            trusted brands, sites, and platforms across the
                            internet such as Business Insider, Youtube, Spotify,
                            and Pinterest.
                        </li>
                    </ul>
                    <p>
                        Since we’ll handle it all for you, you'll save valuable
                        time that you can invest in your business. Once the
                        content is submitted, you’ll also get a detailed report
                        showing all the sites your press release is distributed
                        to and how it’s performing.
                    </p>

                    <p>
                        Learn more about how {{ company }}'s fully managed
                        content creation, repurposing, and distribution solution
                        works
                        <router-link to="/info">here.</router-link>
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import type { ClientCabin } from '@/types/ClientCabin';

@Component
export default class GoogleNews extends Vue {
    @InjectReactive({
        from: 'options',
        default() {
            return false;
        }
    })
    options!: Partial<ClientCabin> | null;

    get company() {
        return this.options?.business_name || 'Our Company';
    }
}
</script>
